//
// Button Components
//

.btn
  &.is-loading
    color transparent !important
    pointer-events none
    position relative
    &:after
      animation spin-around 500ms infinite linear
      border 2px solid #dbdbdb
      border-radius 290486px
      border-right-color transparent
      border-top-color transparent
      content ""
      display block
      height 16px
      position relative
      width 16px
      left 50%
      margin-left -8px
      margin-top -8px
      position absolute
      top 50%
      position absolute !important

@keyframes spin-around
  from
    transform rotate(0deg)
  to
    transform rotate(359deg)