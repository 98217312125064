.popup
  text-align center
  background #fff
  border 1px solid #888
  border-radius 5px

  &:before
    content ""
    position absolute
    top -9px
    left 50%
    margin-left -9px
    display block
    width 0px
    height 0px
    border-style solid
    border-width 0 9px 9px 9px
    border-color transparent transparent #fff transparent
    z-index 0
  &:after
    content ""
    position absolute
    top -10px
    left 50%
    margin-left -10px
    display block
    width 0px
    height 0px
    border-style solid
    border-width 0 10px 10px 10px
    border-color transparent transparent #888 transparent
    z-index -1
