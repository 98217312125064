ul.photos-list
  list-style none
  margin 0
  padding 0
  &>li
    width 25%
    float left
    text-align center
    .selectable
      position relative
      display block
      cursor pointer
      border 1px solid #ccc
      margin 2px
      padding 3px
      &.selected
        padding 2px
        border 2px solid #367fa9
      .remove
        position absolute
        top 0
        right 0
        padding 3px 6px
        margin-top -10px
        margin-right -10px
        font-size 14px
        background transparent
        color #97a0b3
        &:hover
          color #333
    img
      max-width 100%
      height auto
