.content-header {
  .go-back {
    position: relative;
    float: none;
    margin-bottom: 10px;
    padding-left: 0;

    li {
      display: inline-block;

      a {
        color: #444;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
      }
    }

    li:before {
      padding: 0 5px;
      color: #ccc;
      content: "<";
    }
  }
}

