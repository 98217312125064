.contents {
  padding: 30px 0;
}
.box.no-border-box {
  border-top: none;
  box-shadow: none;
}
.box.no-border-box .box-header.with-border {
  border-bottom: 1px solid #ddd;
}
.box-lang-selector {
  padding: 5px 10px;
}
a[href] {
  cursor: pointer;
}
.login-page {
  background: #f39c12;
}
.login-logo {
  color: #fff;
}
.navbar-nav>.user-menu>.dropdown-menu>.user-footer .btn.logout {
  background-color: #960000;
  color: #fff;
  border-width: 0;
}
.input-group--count {
  position: relative;
}
.input-group--count:after {
  position: absolute;
  top: 43%;
  right: 12px;
  content: '件';
  line-height: 34px;
}
.input-group--count input {
  text-align: right;
  padding-right: 30px;
}
.btn.is-loading {
  color: transparent !important;
  pointer-events: none;
  position: relative;
}
.btn.is-loading:after {
  animation: spin-around 500ms infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 16px;
  position: relative;
  width: 16px;
  left: 50%;
  margin-left: -8px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  position: absolute !important;
}
@-moz-keyframes spin-around {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin-around {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@-o-keyframes spin-around {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes spin-around {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.cropper-row img {
  max-width: 50%;
  max-height: 50%;
}
.content-header .go-back {
  position: relative;
  float: none;
  margin-bottom: 10px;
  padding-left: 0;
}
.content-header .go-back li {
  display: inline-block;
}
.content-header .go-back li a {
  color: #444;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}
.content-header .go-back li:before {
  padding: 0 5px;
  color: #ccc;
  content: "<";
}
ul.photos-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.photos-list>li {
  width: 25%;
  float: left;
  text-align: center;
}
ul.photos-list>li .selectable {
  position: relative;
  display: block;
  cursor: pointer;
  border: 1px solid #ccc;
  margin: 2px;
  padding: 3px;
}
ul.photos-list>li .selectable.selected {
  padding: 2px;
  border: 2px solid #367fa9;
}
ul.photos-list>li .selectable .remove {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px 6px;
  margin-top: -10px;
  margin-right: -10px;
  font-size: 14px;
  background: transparent;
  color: #97a0b3;
}
ul.photos-list>li .selectable .remove:hover {
  color: #333;
}
ul.photos-list>li img {
  max-width: 100%;
  height: auto;
}
.popup {
  text-align: center;
  background: #fff;
  border: 1px solid #888;
  border-radius: 5px;
}
.popup:before {
  content: "";
  position: absolute;
  top: -9px;
  left: 50%;
  margin-left: -9px;
  display: block;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 9px 9px 9px;
  border-color: transparent transparent #fff transparent;
  z-index: 0;
}
.popup:after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -10px;
  display: block;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #888 transparent;
  z-index: -1;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 26px;
}
.switch input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
.slider.round {
  border-radius: 30px;
}
.slider.round:before {
  border-radius: 50%;
}
.loading-mask {
  position: relative;
}
.loading-mask-content {
  display: none;
}
.loading .loading-mask-content {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  background: rgba(255,255,255,0.5);
  z-index: 10;
}
.table thead th {
  white-space: nowrap;
}
.wall-body {
  width: 100%;
  min-height: 150px;
  background-color: #fff;
}
