.contents {
    padding: 30px 0;
}

.box.no-border-box {
  border-top: none;
  box-shadow: none;

  .box-header.with-border {
      border-bottom: 1px solid #ddd;
  }
}

.box-lang-selector {
  padding: 5px 10px;
}

a[href]
  cursor pointer

.login-page {
  background :#f39c12;
}

.login-logo {
  color: #fff;
}

.navbar-nav>.user-menu>.dropdown-menu>.user-footer .btn.logout {
  background-color: #960000;
  color: #fff;
  border-width: 0;
}

.input-group--count
  position relative
  &:after
    position absolute
    top 43%
    right 12px
    content '件'
    line-height 34px
  input
    text-align right
    padding-right 30px

@import 'components/*'
