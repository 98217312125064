.loading-mask
  position relative
.loading-mask-content
  display none
.loading .loading-mask-content
  position absolute
  display block
  width 100%
  height 100%
  vertical-align middle
  text-align center
  background rgba(255, 255, 255, 0.5)
  z-index 10

.table thead th
  white-space nowrap
